.footer {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgb(0, 77, 255, 0.2), rgba(255, 255, 255, 0.8), rgba(177, 208, 230));
    position: absolute;
    bottom: 0;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
  }
  
  .icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    font-size: 30px;
    margin: 0 30px;
    color: #fff;
  }
  
  @media screen and (max-width: 768px) {
    .footer {
      height: 60px;
    }
    .icon {
      font-size: 30px;
      margin: 0 5px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .footer {
      height: 40px;
    }
    .icon {
      font-size: 30px;
      margin: 0 5px;
    }
  }
  