.intro-text-container {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
  }
  
  @media screen and (min-width: 768px) {
    .intro-text-container {
      left: 70%;
      transform: translate(-50%, -50%);
      text-align: left;
      width: 40%;
    }
  }
  
  .intro-text {
    display: inline-block;
    position: relative;
    font-size: 1rem;
    color: #fff;
    background-color: rgba(36, 35, 35, 0.2);
    border: 1px solid rgba(128, 128, 128, 0.4);
    padding: 1em 1.5em;
    box-shadow: inset 0 0 10px rgba(67, 77, 207, 0.5);
    max-width: 90%;
    word-wrap: break-word;
    white-space: normal;
  }

@media screen and (min-width: 768px) {
    .intro-text {
        background-color: rgba(128, 128, 128, 0.2);
        font-size: 1.2rem;
    }
  }
  
  .intro-text__border {
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border: 1px solid rgba(128, 128, 128, 0.4);
    z-index: -1;
  }
  
  .intro-text__content {
    position: relative;
    overflow: hidden;
  }
  
  .intro-text__cursor {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2em;
    width: 0.4em;
    height: 1em;
    background-color: #fff;
    animation: blink 1s step-start infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
  