/* Navbar.css */

.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-image: linear-gradient(to bottom, rgb(177, 208, 230), rgba(255, 255, 255, 0.8), rgba(0, 77, 255, 0.2));
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

@media only screen and (min-width: 768px) {
    .navbar-container {
        padding: 1.5rem 2rem;
    }
}

.logo {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    background-image: url("../../images/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 80px;
}

@media only screen and (min-width: 768px) {
    .logo {
        margin-right: 2rem;
        height: 70px;
    }
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 16px;
    cursor: pointer;
    color: black;
    transition: transform 0.4s ease-in-out;
}

@media only screen and (min-width: 768px) {
    .toggle-button {
        display: none;
    }
}

.hamburger-line {
    width: 20px;
    height: 2px;
    background-color: #0066ff;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.hamburger-line.active:first-child {
    transform: translateY(7px) rotate(45deg);
}

.hamburger-line.active:nth-child(2) {
opacity: 0;
}

.hamburger-line.active:last-child {
transform: translateY(-7px) rotate(-45deg);
}
  
.nav-links {
    position: fixed;
    top: 80px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(36, 35, 35, 0.2);
    border: 1px solid rgba(128, 128, 128, 0.4);
    padding: 1em 1.5em;
    box-shadow: inset 0 0 10px rgba(67, 77, 207, 0.5);
    transition: all 0.3s ease-in-out;
    z-index: 999;
    width: 50%;
}

.navlink-wrapper {
    flex-direction: column;
    width: 100%;
    z-index: 3;
}

@media only screen and (min-width: 768px) {
    .navlink-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: auto;
    }
    .nav-links {
        display: flex !important;
        position: static;
        flex-direction: row;
        align-items: center;
        transform: none;
        opacity: 1;
        pointer-events: auto;
        background-color: transparent;
        border: 1px solid rgba(128, 128, 128, 0.4);
        padding: 1em 1.5em;
        box-shadow: inset 0 0 10px rgba(67, 77, 207, 0.5);
        width: auto;
    }
}

.nav-link {
    color: #fff;
    display: block;
    padding: 0.5rem 1rem;
    font-size: 1em;
    text-decoration: none;
}

@media only screen and (min-width: 768px) {
    .nav-link {
        border-bottom: none;
        color: #0066ff;
        padding: 0 1rem;
    }
}

.divider {
    margin: 0.5rem 0;
    border: none;
    height: 1px;
    background-color: rgba(36, 35, 35, 0.2);
    border: 1px solid rgba(128, 128, 128, 0.4);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

#appLauncher {
    background-color: #0072ff;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 12px 24px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    #appLauncher {
        margin: 0 10px;
    }
}

#appLauncher:hover {
    background-color: #005ae6;
}